import React from "react";
import YouTubeFeed from "../../components/YouTubeFeed";
import './videos.css';

function Entertainment(props) {
    // console.log("Dashboard props", props);

    return (
        <>
            <div className='rowC'>
                <YouTubeFeed title="Entertainment" YouTubeAPIURL="https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&playlistId=PLBxGkP0lsM1nfDNp1H5c1RWaAQfL8YrP4&key=AIzaSyDitm-yjrHI8uUGVNMPYXZ_x5gGl2ntojk&maxResults=50" />
            </div>
        </>
    );
}

export default Entertainment;
