import React, { useState, useEffect } from "react";
import axios from "axios";
import Posts from "./Posts";
import Pagination from "./Pagination";
import X2JS from "x2js";
import { useLocation, NavLink } from 'react-router-dom';
import Section from '../pages/Section';
import './Feed.css';

const chucksRssFeed = "https://anchor.fm/s/e6389410/podcast/rss";

const Feed = () => {
  const location = useLocation();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  useEffect(() => {
    const loadPosts = async () => {
      let list = [];
      setLoading(true);
      const res = await axios
        .get(chucksRssFeed, {
          "Content-Type": "application/xml; charset=utf-8"
        })

      var x2js = new X2JS();
      var document = x2js.xml2js(res.data);
      // console.log('document', document);
      // console.log('document', document.rss.channel.item[0].title[1].toString());
      list = document.rss.channel.item;
      // console.log('list', list);
      setPosts(list);
      setLoading(false);
    };

    loadPosts();
  }, []);

  //get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <Section>
        {location.pathname.includes("podcast") && (
          <>
            <h3>Podcast Episodes</h3>
            <Posts posts={currentPosts} loading={loading} />
            <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} />
          </>
        )}
        {!location.pathname.includes("podcast") && (
          <>
            <NavLink to="/podcast"><h3>Listen</h3></NavLink>
            <Posts posts={currentPosts} loading={loading} />
            <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} />
          </>
        )}
      </Section>
    </>
  );

};

export default Feed;