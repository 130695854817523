import React, { useState, useEffect } from "react";
import Navbar from "../Nav/Navbar";

const MainNavigation = (props) => {
    console.log("main navigation props", props);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            console.log("MainNavigation in useEffect");
            loaded();
        }
    }, [isLoading]);

    function loaded() {
        setIsLoading(false);
    }

    return (
        <>
            <div className="App">
                <Navbar />
            </div>
        </>
    );
};

export default MainNavigation;
