import React from "react";

const Welcome = (props) => {
  return (
    <div class="container p-4 p-md-2  rounded text-bg-light">
      <div className='rowC'>
        <div class="text">
          <h1 class="display-4 fst-italic">Welcome to Your Online Coffee Break!</h1>
          <p class="lead my-3">Explore interviews with musicians and entertainers, as well as discussions on Christianity and space exploration, on this engaging and thought-provoking podcast. Tune in to hear from a diverse array of guests and topics. </p>
        </div>
          <div>&nbsp;&nbsp;</div>
        

      </div>


    </div>
  );
};

export default Welcome;
