import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import { NavLink } from "react-router-dom";
import logo from '../../logo.png';
import background from '../../buildings.jpg';
import '../layout/MainNavigation.css';

const Nav = styled.nav`
  width: 100%;
  height: 125px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;

  .logo {
    padding: 15px 0;
  }
`
const Navbar = () => {
  console.log ("navbar");
  return (
    <Nav className="row navbar" style={{
      backgroundImage: `url(${background})`
      , backgroundRepeat: "noRepeat",
      backgroundPosition: 'center',
      backgroundSize: 'cover'
  }}>
      <div className="logo">
      <NavLink to="/" className="navbar-logo" >
                            <img src={logo} alt="Your Online Coffee Break podcast logo" className="imgLogo" />
                        </NavLink>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar;