import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Feed from './components/Feed';
import YouTubeFeed from './components/YouTubeFeed';
import Layout from "./components/layout/Layout";
import ErrorPage from "./pages/Error";
import Home from "./pages/Home";
import Footer from "./components/layout/Footer";
import Christian from './components/videos/Christian';
import Entertainment from "./components/videos/Entertainment";
import Music from './components/videos/Music';
import Space from './components/videos/Space';

function App() {
  // const [isLoading, setLoading] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: (
            <Home />
          ),
        },
        {
          path: "/videos",
          element: (
            <YouTubeFeed />
          ),
        },
        {
          path: "/podcast",
          element: (
            <Feed />
          ),
        },
        {
          path: "/entertainment",
          element: (
            <Entertainment />
          ),
        },
        {
          path: "/music",
          element: (
            <Music />
          ),
        },
        {
          path: "/christian",
          element: (
            <Christian />
          ),
        },
        {
          path: "/space",
          element: (
            <Space />
          ),
        },
        {
          path: "/home",
          element: (
            <Home />
          ),
        },
      ],
    },

  ]);

  return (
    <>
      <RouterProvider router={router} />
      <Footer />
    </>
  );
}

export default App;
