import React from "react";
import PostFeed from '../components/Feed';
import YouTubeFeed from "../components/YouTubeFeed";
import Welcome from "../components/Welcome";
import './Home.css';

function Home(props) {
    // console.log("Dashboard props", props);

    return (
        <>
            <Welcome />
            <div className='rowC'>
            <YouTubeFeed title="Watch" YouTubeAPIURL="https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&playlistId=PLBxGkP0lsM1mdEpbNkJblTBoKIQe_2oz0&key=AIzaSyDitm-yjrHI8uUGVNMPYXZ_x5gGl2ntojk&maxResults=50" />
            <PostFeed />
        </div>
        </>
    );
}

export default Home;
