import React from "react";
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

const Posts = ({ posts, loading }) => {
    const location = useLocation();
    if (loading) {
        // console.log("loading...")
        return <h2>Loading...</h2>
    }

    return (
        <>
            {!loading && posts && (
                posts.map((post) => (
                    <>
                        <div class="card" className="flex-row" key={post.id}>
                            {post.image &&  location.pathname.includes("podcast") &&  (<img class="card-img" style={{ height: '15%' }} alt={parse(post.title)} src={post.image._href} />)}
                            <div class="card-body">
                                <h4 class="card-title h5 h4-sm">{parse(post.title)}</h4>
                                {Object.prototype.toString.call(post.pubDate) === "[object Date]" && (
                                    <div className="mb-2 text-muted">{new Date(post.pubDate).toLocaleDateString()}</div>
                                )}
                                {post.season && post.episode && (
                                     <div className="mb-2 text-muted">Season {post.season.__text} Episode {post.episode.__text} </div>
                                )}
                                {location.pathname.includes("podcast") && (<p class="card-text">{parse(post.description)}</p>)}
                                
                                <div>
                                    <audio controls>
                                        <source src={post.enclosure._url} type="audio/mpeg"  controls preload="metadata"/>
                                        <p>
                                            Download <a href={post.enclosure._url}>MP3</a> audio.
                                        </p>
                                    </audio>
                                </div>

                            </div>
                        </div>
                        <hr />
                    </>
                ))
            )}
        </>
    )
};

export default Posts;